
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import UserForm from '@/components/forms/UserForm.vue';

@Options({
    components: {
        Breadcrumb, UserForm
    }
})

export default class AdminCreate extends Vue {}
