<template>
    <breadcrumb btnText="返回列表" btnIcon="n-direction-left" @btn-click="$router.go(-1)"></breadcrumb>

    <div class="page-admin-create">
        <user-form title="新建管理员" type="admin"></user-form>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import UserForm from '@/components/forms/UserForm.vue';

@Options({
    components: {
        Breadcrumb, UserForm
    }
})

export default class AdminCreate extends Vue {}
</script>